import React from 'react';
import { Stack, TextField } from '@fluentui/react';
import { Controller } from 'react-hook-form';
import { getRequiredError, getVinError } from 'app/forms/validation/validation-errors';
import { hasNoForbiddenCharacter } from 'app/forms/validation/validations';
import useThemeContext from 'app/theming/theme-context';
import { colorCoatingOptions } from './color-coating-type';
import { carTypeOptions } from './car-type';
import ReportSection from './report-section';
import SectionDetailProperties from './section-detail-properties';
import ThemedSelect from 'app/forms/themed-select/themed-select';

export default function VehicleDetailsSection(props: SectionDetailProperties) {

    const theme = useThemeContext();
    return (
        <ReportSection headerText='Angaben zum Fahrzeug'>
            <Controller
                as={
                    <TextField
                        label="Automodell"
                        errorMessage={getRequiredError(props.errors, 'carModel', 'Bitte Automodell angeben')}
                    />
                }
                name="carModel"
                control={props.control}
                rules={{ required: true }}
            />

            <div>
                <Controller
                    as={
                        <ThemedSelect
                            label='Fahrzeugart'
                            options={carTypeOptions}
                            placeholder=''
                            errorMessage={getRequiredError(props.errors, 'carType', 'Bitte Fahrzeugart auswählen')}
                        />
                    }
                    name="carType"
                    control={props.control}
                    rules={{ required: true }}
                />
            </div>

            <Stack horizontal>
                <div style={{ width: '60%' }}>
                    <Controller
                        as={
                            <TextField
                                label="Farblackierung"
                                errorMessage={getRequiredError(props.errors, 'colorCoating', 'Bitte Farblackierung angeben')}
                            />
                        }
                        name="colorCoating"
                        rules={{ required: true }}
                        control={props.control}
                    />
                </div>

                <div style={{ width: '40%', paddingLeft: theme.spacing.m }}>
                    <Controller
                        as={
                            <ThemedSelect
                                label='Beschichtung'
                                options={colorCoatingOptions}
                                placeholder=''
                            />
                        }
                        name="colorCoatingType"
                        control={props.control}
                        rules={{ required: true }}
                    />
                </div>
            </Stack>

            <Controller
                as={
                    <TextField
                        label="FIN"
                        errorMessage={getVinError(props.errors, 'vin')}
                    />
                }
                name="vin"
                control={props.control}
                rules={{
                    required: true,
                    minLength: 17,
                    maxLength: 17,
                    validate: {
                        forbiddenCharacter: (value: string) => hasNoForbiddenCharacter(value, ['i', 'o', 'q', 'I', 'O', 'Q']),
                    }
                }}
            />
            <Controller
                as={<TextField label="TSN (optional)" />}
                name="typeKeyNumber"
                control={props.control}
            />

            <Controller
                as={<TextField label="Frachtbriefnummer (optional)" />}
                name="wayBillNumber"
                control={props.control}
            />
        </ReportSection>
    );
}

export const vehicleDetailsSectionInputNames: string[] = [
    'carModel',
    'colorCoating',
    'colorCoatingType',
    'vin',
    'typeKeyNumber',
    'wayBillNumber'
]
